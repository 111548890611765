<template>
  <div class="success-component">
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">{{pageTitle}}</div>
      <div class="main has-background-white has-text-centered">
        <div class="section-title">{{pageSubtitle}}</div>
        <img src="@assets/images/wallet/add-funds-success.svg" alt="success image">
        <div class="has-text-lavender">{{pageDesc}}</div>
      </div>
      <div class="bottom is-clearfix">
        <b-button
          type="is-primary"
          class="is-medium-height button-next has-text-weight-bold is-pulled-right finish-button"
          rounded
          @click="goNext"
        >Finish</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  components: {
    MenuAndLogo,
  },
  props: {
    flow: {
      type: String,
      required: true,
    }
  },
  computed: {
    isAddFunds() {
      return this.flow === 'add-funds'
    },
    pageTitle() {
      return this.isAddFunds ? 'Add funds' : 'Withdraw funds'
    },
    pageSubtitle() {
      return this.isAddFunds ? 'You successfully submitted your deposit request— your funds are on the way! 💸' : 'We’ve gotten your withdrawal request— your funds are on the way! 💸'
    },
    pageDesc() {
      return this.isAddFunds ? 'Please allow for 7 business days for your bank to process and transfer your deposit.' : 'Please allow for 3 - 5 business days for your withdrawal to be processed.'
    },
  },
  methods: {
    goNext() {
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.push('/wallet')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.success-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      line-height: 19px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 22px 18px;
      .section-title {
        padding-bottom: 28px;
      }
      img {
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
    .finish-button {
      width: 106px;
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .success-component {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
    .bottom.is-clearfix {
      padding-bottom: 20px;
    }
  }
}
</style>
